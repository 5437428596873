import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css'

import Home from './Components/home';
import BookNow from './Components/booknow';
import RegisterOwner from './Components/registerowner';
import RegisterDriver from './Components/registerdriver';
import ContactUs from './Components/contactus';

// import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
  },
  {
    path: "ContactUs",
    element: <ContactUs/>,
  },
  {
    path: "RegisterOwner",
    element: <RegisterOwner/>,
  },
  {
    path: "Home",
    element: <Home/>,
  },
  {
    path: "BookNow",
    element: <BookNow/>,
  },
  {
    path: "RegisterDriver",
    element: <RegisterDriver/>,
  },

  
]);



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 <RouterProvider router  = {router}/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
