import React, { useState } from 'react';
import '../Components/contactus.css';
import DOMPurify from 'dompurify'; // Import DOMPurify
import Navbar from './navbar';
import Footer from './footer';

function ContactUs(props) {
  const [formData, setFormData] = useState({
    By: '',
    Nåværende_status: '',
    Navn: '',
    EPost: '',
    Telefon: '',
    Melding: ''
  });
  const [formErrors, setFormErrors] = useState({});
  function sanitizeInput(input) {
    return DOMPurify.sanitize(input);
  }
  function validateForm() {
    let errors = {};
    let formIsValid = true;

    // Example: Validate required fields
    if (!formData.By) {
      formIsValid = false;
      errors.By = "By er påkrævet";
    }
    if (!formData.Nåværende_status) {
      formIsValid = false;
      errors.Nåværende_status = "Nåværende_status er påkrævet";
    }
    if (!formData.Navn) {
      formIsValid = false;
      errors.Navn = "Navn er påkrævet";
    }
    if (!formData.EPost) {
      formIsValid = false;
      errors.EPost = "EPost er påkrævet";
    }
    if (!formData.Telefon) {
      formIsValid = false;
      errors.Telefon = "Telefon er påkrævet";
    }

    setFormErrors(errors);
    return formIsValid;
  }
  function handleSubmit(e) {
    e.preventDefault();
    if (validateForm()) {


      const sanitizedData = {
        By: sanitizeInput(formData.By),
        Nåværende_status: sanitizeInput(formData.Nåværende_status),
        Navn: sanitizeInput(formData.Navn),
        EPost: sanitizeInput(formData.EPost),
        Telefon: sanitizeInput(formData.Telefon),
        Melding: sanitizeInput(formData.Melding),
      };

      const formDatab = new FormData();
      formDatab.append('By', sanitizedData.By);
      formDatab.append('Nåværende_status', sanitizedData.Nåværende_status);
      formDatab.append('Navn', sanitizedData.Navn);
      formDatab.append('EPost', sanitizedData.EPost);
      formDatab.append('Telefon', sanitizedData.Telefon);
      formDatab.append('Melding', sanitizedData.Melding);
      formDatab.append('sheet', 'contactusformdata'); // Specify the sheet parameter here

      fetch("https://script.google.com/macros/s/AKfycbxHi7oWSPwSNHumHw0tevfhHPy3Ca2eU0zvVxSlu-2dq8ITHRhaFjuap8M_imqGq81rqw/exec", {
        method: "POST",
        body: formDatab
      }).then(response => {
        console.log('Success:', response);
        setFormData({
          By: '',
          Nåværende_status: '',
          Navn: '',
          EPost: '',
          Telefon: '',
          Melding: ''
        })

      }).catch(error => {
        console.error('Error:', error);
      });
    }

  }
  return (
    <>
      <Navbar />
      <div className="c-text">
        <h1><b>Bli en del av VIPCabs -teamet i dag</b></h1>
        <p>Vi tilbyr gratis innmontering og opptil 10% rabatt på<br />kjøp av bil via vår samarbeidspartner. Tilbudet gjelder<br />fram til 31.januar 2024. Hva venter du på? Registrer<br />deg hos oss i dag</p>
      </div>
      <div className="booking-form-container">
        <h1>Vennligst fyll ut dette skjemaet</h1>
        <form className="booking-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="By">By</label>
            <input type="text" id="By" name="By" value={formData.By} onChange={(e) => setFormData({ ...formData, By: e.target.value })} />
            {formErrors.By && <span className="error">{formErrors.By}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="Nåværende_status">Nåværende status</label>
            <input type="text" id="Nåværende_status" name="Nåværende_status" value={formData.Nåværende_status} onChange={(e) => setFormData({ ...formData, Nåværende_status: e.target.value })} />
            {formErrors.Nåværende_status && <span className="error">{formErrors.Nåværende_status}</span>}

          </div>
          <div className="form-group">
            <label htmlFor="Navn">Navn</label>
            <input type="text" id="Navn" name="Navn" value={formData.Navn} onChange={(e) => setFormData({ ...formData, Navn: e.target.value })} />
            {formErrors.Navn && <span className="error">{formErrors.Navn}</span>}

          </div>
          <div className="form-group">
            <label htmlFor="EPost">E-post</label>
            <input type="email" id="EPost" name="EPost" value={formData["EPost"]} onChange={(e) => setFormData({ ...formData, "EPost": e.target.value })} />
            {formErrors.EPost && <span className="error">{formErrors.EPost}</span>}
          </div>
          <div className="form-group full-width">
            <label htmlFor="Telefon">Telefon</label>
            <input type="tel" id="Telefon" name="Telefon" value={formData.Telefon} onChange={(e) => setFormData({ ...formData, Telefon: e.target.value })} />
            {formErrors.Telefon && <span className="error">{formErrors.Telefon}</span>}
          </div>
          <div className="form-group full-width">
            <label htmlFor="Melding">Melding</label>
            <textarea className='c-textarea' id='Melding' rows="4" cols="50" name="Melding" form="usrform" placeholder='Skriv din forespørsel her...' value={formData.Melding} onChange={(e) => setFormData({ ...formData, Melding: e.target.value })}>
            </textarea>
          </div>
          <button className='c-btn contact_us_submit'>
            Bestill
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
            </svg>
          </button>
        </form>
      </div>
      <div className="services-container-c">
        <div className="services-grid-c">
          <div className="service-card-c">
            <div className="circle1"></div>
            <h2>Ring oss på</h2>
            <p>Mob: 004796943090</p>
          </div>
          <div className="service-card-c">
            <div className="circle2"></div>
            <h2>Send oss ​​en e-post</h2>
            <p>post@vipcabs.no</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ContactUs;
