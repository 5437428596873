import React from 'react';
import '../Components/footer.css';

function Footer(props) {
    return (
        
        <footer className="footer">
            <hr className='f-hr'/>
        <div className="container">
            <div className="row">
                <div className="col-lg-4 col-md-12 mb-12 col-sm-12 column">
                    <img src="/assets/logo.png" alt="Logo" className="logo"/>
                    <p>
                    Med en sentralavgift helt ned mot kr 2 699 per måned kan du oppfylle drømmen om å bli drosjeeier. Sentralavgift er en stor kostnad for en drosjeeier. Mytaxi har derfor ambisjoner om å ha de laveste sentralavgiftene i Norge.
                    </p>
                </div>
                <div className="col-lg-4 col-md-6 mb-6 col-sm-6 column">
                    <h5><b>Hurtigkoblinger</b></h5>
                    <ul className="list-unstyled">
                        <li><a href="#home">Tjenester</a></li>
                        <li><a href="#services">Hjem</a></li>
                        <li><a href="#contact">Sentral oslo cabs</a></li>
                        <li><a href="#about">Vip cabs</a></li>
                        <li><a href="#about">Kontakt oss</a></li>
                    </ul>
                </div>
                <div className="col-lg-4 col-md-6 mb-6 col-sm-6 column">
                    <h5><b>Nå oss på</b></h5>
                    <ul className="list-unstyled">
                        <li><svg xmlns="http://www.w3.org/2000/svg" id='f-icon' width="16" height="16" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
                        </svg>&nbsp;&nbsp;Mob: 004796943090</li>
                        <li><svg xmlns="http://www.w3.org/2000/svg" id='f-icon' width="16" height="16" fill="currentColor" className="bi bi-envelope-fill" viewBox="0 0 16 16">
                        <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z"/>
                        </svg>&nbsp;&nbsp;post@vipcabs.no</li>
                    </ul>
                </div>
            </div>
            <div className="bottom-text">
                <p>2024 VIP Cabs. All rights reserved.</p>
            </div>
        </div>
    </footer>
    );
}

export default Footer; 