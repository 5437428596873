import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import Navbar from './navbar';
import Footer from './footer';
import '../Components/registerowner.css';

function RegisterOwner(props) {
  const [formData, setFormData] = useState({
    Firmanavn: '',
    Organisasjonsnummer: '',
    Adresse: '',
    Postnummer: '',
    Sted: '',
    Innehaver: '',
    Nasjonalitet: '',
    Fødeby: '',
    Fødselsdato: '',
    Mobil: '',
    Email: '',
    Bankkonto: '',
    IBAN: '',
    Løyvenummer1: '',
    Løyvenummer2: '',
    personalFørerkort: '',
    personalKjøreseddel: '',
    personalPersonligBilde: '',
    companyFirmaattest: '',
    companyLøyvedokument: '',
    companyVognkort: '',
    stromfaktura: '',
    internetfaktura: '',
    kontoutskrift: '',
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let formErrors = {};
    const requiredFields = [
      'Firmanavn', 'Organisasjonsnummer', 'Adresse', 'Postnummer', 'Sted',
      'Innehaver', 'Nasjonalitet', 'Fødeby', 'Fødselsdato', 'Mobil', 'Email',
      'Bankkonto', 'Løyvenummer1'
    ];

    requiredFields.forEach(field => {
      if (!formData[field]) {
        switch (field) {
          case 'Firmanavn':
            formErrors[field] = 'Firmanavn er påkrevd';
            break;
          case 'Organisasjonsnummer':
            formErrors[field] = 'Organisasjonsnummer er påkrevd';
            break;
          case 'Adresse':
            formErrors[field] = 'Adresse er påkrevd';
            break;
          case 'Postnummer':
            formErrors[field] = 'Postnummer er påkrevd';
            break;
          case 'Sted':
            formErrors[field] = 'Sted er påkrevd';
            break;
          case 'Innehaver':
            formErrors[field] = 'Innehaver er påkrevd';
            break;
          case 'Nasjonalitet':
            formErrors[field] = 'Nasjonalitet er påkrevd';
            break;
          case 'Fødeby':
            formErrors[field] = 'Fødeby er påkrevd';
            break;
          case 'Fødselsdato':
            formErrors[field] = 'Fødselsdato er påkrevd';
            break;
          case 'Mobil':
            formErrors[field] = 'Mobil er påkrevd';
            break;
          case 'Email':
            formErrors[field] = 'Email er påkrevd';
            break;
          case 'Bankkonto':
            formErrors[field] = 'Bankkonto er påkrevd';
            break;
          case 'Løyvenummer1':
            formErrors[field] = 'Løyvenummer1 er påkrevd';
            break;
          default:
            formErrors[field] = 'Dette feltet er obligatorisk';
        }
      }
    });

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    const sanitizedData = {};
    Object.keys(formData).forEach(key => {
      sanitizedData[key] = DOMPurify.sanitize(formData[key]);
    });

    console.log("Sanitized Data: ", sanitizedData);

    const formDatab = new FormData();
    Object.keys(sanitizedData).forEach(key => {
      formDatab.append(key, sanitizedData[key]);
    });
    formDatab.append('sheet', 'registerownerformdata'); // Specify the sheet parameter here

    fetch("https://script.google.com/macros/s/AKfycbxHi7oWSPwSNHumHw0tevfhHPy3Ca2eU0zvVxSlu-2dq8ITHRhaFjuap8M_imqGq81rqw/exec", {
      method: "POST",
      body: formDatab
    }).then(response => {
      console.log('Success:', response);
      setFormData({
        Firmanavn: '',
        Organisasjonsnummer: '',
        Adresse: '',
        Postnummer: '',
        Sted: '',
        Innehaver: '',
        Nasjonalitet: '',
        Fødeby: '',
        Fødselsdato: '',
        Mobil: '',
        Email: '',
        Bankkonto: '',
        IBAN: '',
        Løyvenummer1: '',
        Løyvenummer2: '',
        personalFørerkort: '',
        personalKjøreseddel: '',
        personalPersonligBilde: '',
        companyFirmaattest: '',
        companyLøyvedokument: '',
        companyVognkort: '',
        stromfaktura: '',
        internetfaktura: '',
        kontoutskrift: '',
      });
    }).catch(error => {
      console.error('Error:', error);
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  return (
    <>
      <Navbar />
      <div>
        <div className="r-hero col-12 row">
          <div className="col-6 text">
            <h1><b>Mål</b></h1>
            <p>VIPCabs er en flåte av luksuriøse kjøretøy, fra sedaner til limousiner, som sikrer en reise i stil og komfort.
              Vi ønsker å utvide vårt lag med flere dyktige drosjeeiere i alle storbyer og tettsteder I Norge.</p>
            <h1><b>VISJON</b></h1>
            <p>VI HAR EN VISJON OM Å GI
              BEST MULIG KUNDESERVICE TIL ALLE VÅRE KUNDER.
              VI ER ET DROSJESELSKAP SOM PRIORITERER VÅRE INVESTOR SAMT ØNSKER Å ØKE VERDIEN
              I DERES INVESTERINGER.</p>
          </div>
          <div className="col-6 text">
            <h1><b>Vi Tilbyr Følgende</b></h1>
            <ul>
              <li>Markedets laveste sentralavgift pålydende kr 2999- inklusiv mva</li>
              <li>Gratis innmontering av taksameter innen 24 timer</li>
              <li>Gratis 1 måned sentral-avgift</li>
              <li>24/7 support</li>
              <li>Direkte penger på egen konto med egen BAX-nummer</li>
              <li>Eget Taksameter Verksted</li>
              <li>Gode Avtaler Med Hoteller Og Fly Selskaper</li>
            </ul>
          </div>
        </div>
        <div className="booking-form-container">
          <h1>Vennligst fyll ut dette skjemaet</h1>
          <form className="booking" onSubmit={handleSubmit}>
            <div className="booking-form">
            {[
              { label: 'Firmanavn / Løyvehaver / Company Name*', id: 'Firmanavn', type: 'text' },
              { label: 'Organisasjonsnummer*', id: 'Organisasjonsnummer', type: 'text' },
              { label: 'Adresse*', id: 'Adresse', type: 'text' },
              { label: 'Postnummer*', id: 'Postnummer', type: 'text' },
              { label: 'Sted / City of living*', id: 'Sted', type: 'text' },
              { label: 'Innehaver / Daglig leder / Director*', id: 'Innehaver', type: 'text' },
              { label: 'Nasjonalitet*', id: 'Nasjonalitet', type: 'text' },
              { label: 'Fødeby / City of birth*', id: 'Fødeby', type: 'text' },
              { label: 'Fødselsdato / Date of Birth*', id: 'Fødselsdato', type: 'date' },
              { label: 'Mobil*', id: 'Mobil', type: 'text' },
              { label: 'Email*', id: 'Email', type: 'email' },
              { label: 'Bankkonto*', id: 'Bankkonto', type: 'text' },
              { label: 'IBAN', id: 'IBAN', type: 'text' },
              { label: 'Løyvenummer 1*', id: 'Løyvenummer1', type: 'text' },
              { label: 'Løyvenummer 2-videre (skriv alle andre løyvenummer her etter den)', id: 'Løyvenummer2', type: 'text' },
            ].map(({ label, id, type }) => (
              <div className={`form-group ${id === 'Adresse' ? 'full-width' : ''}`} key={id}>
                <label htmlFor={id}>{label}</label>
                <input
                  type={type}
                  id={id}
                  name={id}
                  value={formData[id]}
                  onChange={handleInputChange}
                />
                {errors[id] && <span className="error-message">{errors[id]}</span>}
              </div>
            ))}
            </div>
            <div className="per-back1">
              <div className="personal-id-form-container">
                <h2>For personlig ID</h2>
                <p>Send dokument (PDF/jpg) til doslocabs@gmail.com</p>
                <div className="personal-id-form">
                  {[
                    { label: 'Førerkort', name: 'personalFørerkort' },
                    { label: 'Kjøreseddel', name: 'personalKjøreseddel' },
                    { label: 'Personlig bilde', name: 'personalPersonligBilde' }
                  ].map(({ label, name }) => (
                    <div className="document-row" key={name}>
                      <span>{label}</span>
                      <div className="checkbox-group">
                        <label className="form-check-label" htmlFor={`${name}Sender`}>
                          <input
                            className="form-check-input"
                            type="radio"
                            id={`${name}Sender`}
                            name={name}
                            value="Sender"
                            checked={formData[name] === "Sender"}
                            onChange={(e) => setFormData({ ...formData, [name]: e.target.value })}
                          />
                          Sender
                        </label>
                        <label className="form-check-label" htmlFor={`${name}HarSent`}>
                          <input
                            className="form-check-input"
                            type="radio"
                            id={`${name}HarSent`}
                            name={name}
                            value="Har sendt"
                            checked={formData[name] === "Har sendt"}
                            onChange={(e) => setFormData({ ...formData, [name]: e.target.value })}
                          />
                          Har sendt
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="com-back1">
              <div className="company-documents-form-container">
                <h2>For firma ID</h2>
                <p>Send dokument (PDF/jpg) til doslocabs@gmail.com</p>
                <div className="company-documents-form">
                  {[
                    { label: 'Firmaattest', name: 'companyFirmaattest' },
                    { label: 'Løyvedokument', name: 'companyLøyvedokument' },
                    { label: 'Vognkort', name: 'companyVognkort' }
                  ].map(({ label, name }) => (
                    <div className="document-row" key={name}>
                      <span>{label}</span>
                      <div className="checkbox-group">
                        <label className="form-check-label" htmlFor={`${name}Sender`}>
                          <input
                            className="form-check-input"
                            type="radio"
                            id={`${name}Sender`}
                            name={name}
                            value="Sender"
                            checked={formData[name] === "Sender"}
                            onChange={(e) => setFormData({ ...formData, [name]: e.target.value })}
                          />
                          Sender
                        </label>
                        <label className="form-check-label" htmlFor={`${name}HarSent`}>
                          <input
                            className="form-check-input"
                            type="radio"
                            id={`${name}HarSent`}
                            name={name}
                            value="Har sendt"
                            checked={formData[name] === "Har sendt"}
                            onChange={(e) => setFormData({ ...formData, [name]: e.target.value })}
                          />
                          Har sendt
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="ele-back1">
              <div className="electricity-bill-form-container">
                <h2>Strømregning</h2>
                <p>Send dokument (PDF/jpg) til doslocabs@gmail.com</p>
                <div className="electricity-bill-form">
                  {[
                    { label: 'Strømfaktura', name: 'stromfaktura' },
                    { label: 'Internett faktura', name: 'internetfaktura' },
                    { label: 'Kontoutskrift', name: 'kontoutskrift' }
                  ].map(({ label, name }) => (
                    <div className="document-row" key={name}>
                      <span>{label}</span>
                      <div className="checkbox-group">
                        <label className="form-check-label" htmlFor={`${name}Sender`}>
                          <input
                            className="form-check-input"
                            type="radio"
                            id={`${name}Sender`}
                            name={name}
                            value="Sender"
                            checked={formData[name] === "Sender"}
                            onChange={(e) => setFormData({ ...formData, [name]: e.target.value })}
                          />
                          Sender
                        </label>
                        <label className="form-check-label" htmlFor={`${name}HarSent`}>
                          <input
                            className="form-check-input"
                            type="radio"
                            id={`${name}HarSent`}
                            name={name}
                            value="Har sendt"
                            checked={formData[name] === "Har sendt"}
                            onChange={(e) => setFormData({ ...formData, [name]: e.target.value })}
                          />
                          Har sendt
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="btn-div">      
            <button className="register-owner ro-btn" type="submit">Sende inn</button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default RegisterOwner;
