import React, { useState } from 'react';
import Navbar from './navbar';
import DOMPurify from 'dompurify'; // Import DOMPurify
import Footer from './footer';
import '../Components/registerdriver.css'

function RegisterDriver(props) {

  const [formData, setFormData] = useState({
    ForNavn: '',
    Etternavn: '',
    Fødselsdato: '',
    Nasjonalitet: '',
    Fødeland: '',
    Kjønn: '',
    Sivilstand: '',
    Mobilnr: '',
    Alternativ_mobilnr: '',
    Adresse: '',
    Postnr: '',
    Sted: '',
    Førerkort: '',
    Førerkort_gyldig_til: '',
    Kjøreseddel_gyldig_til: '',
    Sjåfør_ID_nr: '',
    Sjåfør_ID_Validy: '',
    Bankkonto: '',
    Annen_jobb_nå: '',
    pårørendes_navn_og_telefonnummer: ''

  });
  const [formErrors, setFormErrors] = useState({});

  function sanitizeInput(input) {
    return DOMPurify.sanitize(input);
  }
  function validateForm() {
    let errors = {};
    let formIsValid = true;

    // Example: Validate required fields
    if (!formData.ForNavn) {
      formIsValid = false;
      errors.ForNavn = "Fornavn er påkrævet";
    }

    if (!formData.Etternavn) {
      formIsValid = false;
      errors.Etternavn = "Etternavn er påkrævet";
    }
    if (!formData.Fødselsdato) {
      formIsValid = false;
      errors.Fødselsdato = "Fødselsdato er påkrævet";
    }
    if (!formData.Nasjonalitet) {
      formIsValid = false;
      errors.Nasjonalitet = "Nasjonalitet er påkrævet";
    }
    if (!formData.Fødeland) {
      formIsValid = false;
      errors.Fødeland = "Fødeland er påkrævet";
    }
    if (!formData.Kjønn) {
      formIsValid = false;
      errors.Kjønn = "Kjønn er påkrævet";
    }
    if (!formData.Sivilstand) {
      formIsValid = false;
      errors.Sivilstand = "Sivilstand er påkrævet";
    }
    if (!formData.Fødselsdato) {
      formIsValid = false;
      errors.Fødselsdato = "Fødselsdato er påkrævet";
    }
    if (!formData.Mobilnr) {
      formIsValid = false;
      errors.Mobilnr = "Mobilnr er påkrævet";
    }
    if (!formData.Adresse) {
      formIsValid = false;
      errors.Adresse = "Adresse er påkrævet";
    }
    if (!formData.Postnr) {
      formIsValid = false;
      errors.Postnr = "Postnr er påkrævet";
    }
    if (!formData.Sted) {
      formIsValid = false;
      errors.Sted = "Sted er påkrævet";
    }
    if (!formData.Førerkort) {
      formIsValid = false;
      errors.Førerkort = "Førerkort er påkrævet";
    }
    if (!formData.Førerkort_gyldig_til) {
      formIsValid = false;
      errors.Førerkort_gyldig_til = "Førerkort_gyldig_til er påkrævet";
    }
    if (!formData.Kjøreseddel_gyldig_til) {
      formIsValid = false;
      errors.Kjøreseddel_gyldig_til = "Kjøreseddel_gyldig_til er påkrævet";
    }
    if (!formData.Sjåfør_ID_nr) {
      formIsValid = false;
      errors.Sjåfør_ID_nr = "Sjåfør_ID_nr er påkrævet";
    }
    if (!formData.Sjåfør_ID_Validy) {
      formIsValid = false;
      errors.Sjåfør_ID_Validy = "Sjåfør_ID_Validy er påkrævet";
    }
    if (!formData.Bankkonto) {
      formIsValid = false;
      errors.Bankkonto = "Bankkonto er påkrævet";
    }
    if (!formData.Annen_jobb_nå) {
      formIsValid = false;
      errors.Annen_jobb_nå = "Annen_jobb_nå er påkrævet";
    }
    if (!formData.pårørendes_navn_og_telefonnummer) {
      formIsValid = false;
      errors.pårørendes_navn_og_telefonnummer = "pårørendes_navn_og_telefonnummer er påkrævet";
    }

    // Add more validations as needed for each field

    setFormErrors(errors);
    return formIsValid;
  }
  function handleSubmit(e) {
    e.preventDefault();

    if (validateForm()) {

      const sanitizedData = {
        ForNavn: sanitizeInput(formData.ForNavn),
        Etternavn: sanitizeInput(formData.Etternavn),
        Fødselsdato: sanitizeInput(formData.Fødselsdato),
        Nasjonalitet: sanitizeInput(formData.Nasjonalitet),
        Fødeland: sanitizeInput(formData.Fødeland),
        Kjønn: sanitizeInput(formData.Kjønn),
        Sivilstand: sanitizeInput(formData.Sivilstand),
        Mobilnr: sanitizeInput(formData.Mobilnr),
        Alternativ_mobilnr: sanitizeInput(formData.Alternativ_mobilnr),
        Adresse: sanitizeInput(formData.Adresse),
        Postnr: sanitizeInput(formData.Postnr),
        Sted: sanitizeInput(formData.Sted),
        Førerkort: sanitizeInput(formData.Førerkort),
        Førerkort_gyldig_til: sanitizeInput(formData.Førerkort_gyldig_til),
        Kjøreseddel_gyldig_til: sanitizeInput(formData.Kjøreseddel_gyldig_til),
        Sjåfør_ID_nr: sanitizeInput(formData.Sjåfør_ID_nr),
        Sjåfør_ID_Validy: sanitizeInput(formData.Sjåfør_ID_Validy),
        Bankkonto: sanitizeInput(formData.Bankkonto),
        Annen_jobb_nå: sanitizeInput(formData.Annen_jobb_nå),
        pårørendes_navn_og_telefonnummer: sanitizeInput(formData.pårørendes_navn_og_telefonnummer),
        Tid: sanitizeInput(formData.Tid)
      };

    const formDatab = new FormData();
    formDatab.append('ForNavn', sanitizedData.ForNavn);
    formDatab.append('Etternavn', sanitizedData.Etternavn);
    formDatab.append('Fødselsdato', sanitizedData.Fødselsdato);
    formDatab.append('Nasjonalitet', sanitizedData.Nasjonalitet);
    formDatab.append('Fødeland', sanitizedData.Fødeland);
    formDatab.append('Kjønn', sanitizedData.Kjønn);
    formDatab.append('Sivilstand', sanitizedData.Sivilstand);
    formDatab.append('Mobilnr', sanitizedData.Mobilnr);
    formDatab.append('Alternativ_mobilnr', sanitizedData.Alternativ_mobilnr);
    formDatab.append('Adresse', sanitizedData.Adresse);
    formDatab.append('Postnr', sanitizedData.Postnr);
    formDatab.append('Sted', sanitizedData.Sted);
    formDatab.append('Førerkort', sanitizedData.Førerkort);
    formDatab.append('Førerkort_gyldig_til', sanitizedData.Førerkort_gyldig_til);
    formDatab.append('Kjøreseddel_gyldig_til', sanitizedData.Kjøreseddel_gyldig_til);
    formDatab.append('Sjåfør_ID_nr', sanitizedData.Sjåfør_ID_nr);
    formDatab.append('Sjåfør_ID_Validy', sanitizedData.Sjåfør_ID_Validy);
    formDatab.append('Bankkonto', sanitizedData.Bankkonto);
    formDatab.append('Annen_jobb_nå', sanitizedData.Annen_jobb_nå);
    formDatab.append('pårørendes_navn_og_telefonnummer', sanitizedData.pårørendes_navn_og_telefonnummer);
    formDatab.append('sheet', 'registerdriverformdata'); // Specify the sheet parameter here
   

    fetch("https://script.google.com/macros/s/AKfycbxHi7oWSPwSNHumHw0tevfhHPy3Ca2eU0zvVxSlu-2dq8ITHRhaFjuap8M_imqGq81rqw/exec", {
      method: "POST",
      body: formDatab
    }).then(response => {
      console.log('Success:', response);
      setFormData({
        ForNavn: '',
        Etternavn: '',
        Fødselsdato: '',
        Nasjonalitet: '',
        Fødeland: '',
        Kjønn: '',
        Sivilstand: '',
        Mobilnr: '',
        Alternativ_mobilnr: '',
        Adresse: '',
        Postnr: '',
        Sted: '',
        Førerkort: '',
        Førerkort_gyldig_til: '',
        Kjøreseddel_gyldig_til: '',
        Sjåfør_ID_nr: '',
        Sjåfør_ID_Validy: '',
        Bankkonto: '',
        Annen_jobb_nå: '',
        pårørendes_navn_og_telefonnummer: ''
      });
    }).catch(error => {
      console.error('Error:', error);
    });
  }
  
  }
  return (
    <>  <Navbar />
      <div className="booking-form-container">
        <h1>Registrer deg som sjåfør</h1>
        <form className="booking-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="ForNavn">ForNavn</label>
            <input type="text" id="ForNavn" name="ForNavn"  value={formData.ForNavn} onChange={e => setFormData({ ...formData, ForNavn: e.target.value })} />
            {formErrors.ForNavn && <span className="error">{formErrors.ForNavn}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="Etternavn">Etternavn</label>
            <input type="text" id="Etternavn" name="Etternavn" value={formData.Etternavn} onChange={e => setFormData({ ...formData, Etternavn: e.target.value })} />
            {formErrors.Etternavn && <span className="error">{formErrors.Etternavn}</span>}
          </div>
          <div className="form-group full-width">
            <label htmlFor="Fødselsdato">Fødselsdato</label>
            <input type="date" id="Fødselsdato" name="Fødselsdato" value={formData.Fødselsdato} onChange={e => setFormData({ ...formData, Fødselsdato: e.target.value })} />
            {formErrors.Fødselsdato && <span className="error">{formErrors.Fødselsdato}</span>}
            </div>
          <div className="form-group">
            <label htmlFor="Nasjonalitet">Nasjonalitet</label>
            <input type="text" id="Nasjonalitet" name="Nasjonalitet" value={formData.Nasjonalitet} onChange={e => setFormData({ ...formData, Nasjonalitet: e.target.value })} />
            {formErrors.Nasjonalitet && <span className="error">{formErrors.Nasjonalitet}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="Fødeland">Fødeland</label>
            <input type="text" id="Fødeland" name="Fødeland" value={formData.Fødeland} onChange={e => setFormData({ ...formData, Fødeland: e.target.value })} />
            {formErrors.Fødeland && <span className="error">{formErrors.Fødeland}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="Kjønn">Kjønn</label>
            <div className="form-check form-check-inline">
              <label className="form-check-label" htmlFor="Menn"><input className="form-check-input" type="radio" id="Menn" name='Kjønn' value="Menn" checked={formData.Kjønn === "Menn"}
                onChange={(e) => setFormData({ ...formData, Kjønn: e.target.value })} />&nbsp;&nbsp;Menn</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <label className="form-check-label" htmlFor="Kvinne"><input className="form-check-input" type="radio" id="Kvinne" name='Kjønn' value="Kvinne" checked={formData.Kjønn === "Kvinne"}
                onChange={(e) => setFormData({ ...formData, Kjønn: e.target.value })} />&nbsp;&nbsp;Kvinne</label>
            </div>
            {formErrors.Kjønn && <span className="error">{formErrors.Kjønn}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="Sivilstand">Sivilstand</label>
            <div className="form-check form-check-inline">
              <label className="form-check-label" htmlFor="Ugift "><input className="form-check-input" type="radio" name='Sivilstand' id="Ugift " value="Ugift " checked={formData.Sivilstand === "Ugift "}
                onChange={(e) => setFormData({ ...formData, Sivilstand: e.target.value })} />&nbsp;&nbsp;Ugift </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <label className="form-check-label" htmlFor="Gift"><input className="form-check-input" type="radio" name='Sivilstand' id="Gift" value="Gift" checked={formData.Sivilstand === "Gift"}
                onChange={(e) => setFormData({ ...formData, Sivilstand: e.target.value })} />&nbsp;&nbsp;Gift</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <label className="form-check-label" htmlFor="Annet"><input className="form-check-input" type="radio" name='Sivilstand' id="Annet" value="Annet" checked={formData.Sivilstand === "Annet"}
                onChange={(e) => setFormData({ ...formData, Sivilstand: e.target.value })} />&nbsp;&nbsp;Annet</label>
            </div>
            {formErrors.Sivilstand && <span className="error">{formErrors.Sivilstand}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="Mobilnr">Mobilnr</label>
            <input type="tel" id="Mobilnr" name="Mobilnr" value={formData.Mobilnr} onChange={e => setFormData({ ...formData, Mobilnr: e.target.value })} />
            {formErrors.Mobilnr && <span className="error">{formErrors.Mobilnr}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="Alternativ_mobilnr">Alternativ mobilnr. <span>(fra familie eller venner)</span></label>
            <input type="tel" id="Alternativ_mobilnr" name="Alternativ_mobilnr" value={formData.Alternativ_mobilnr} onChange={e => setFormData({ ...formData, Alternativ_mobilnr: e.target.value })} />
            {formErrors.Alternativ_mobilnr && <span className="error">{formErrors.Alternativ_mobilnr}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="Adresse">Adresse</label>
            <input type="text" id="Adresse" name="Adresse" value={formData.Adresse} onChange={e => setFormData({ ...formData, Adresse: e.target.value })} />
            {formErrors.Adresse && <span className="error">{formErrors.Adresse}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="Postnr">Postnr</label>
            <input type="text" id="Postnr" name="Postnr" value={formData.Postnr} onChange={e => setFormData({ ...formData, Postnr: e.target.value })} />
            {formErrors.Postnr && <span className="error">{formErrors.Postnr}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="Sted">Sted</label>
            <input type="text" id="Sted" name="Sted" value={formData.Sted} onChange={e => setFormData({ ...formData, Sted: e.target.value })} />
            {formErrors.Sted && <span className="error">{formErrors.Sted}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="Førerkort">Førerkort <span>utstedt fra / Driving Licence issued from (Country name)</span></label>
            <input type="text" id="Førerkort" name="Førerkort" value={formData.Førerkort} onChange={e => setFormData({ ...formData, Førerkort: e.target.value })} />
            {formErrors.Førerkort && <span className="error">{formErrors.Førerkort}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="Førerkort_gyldig_til">Førerkort<span> gyldig til / Driving License valid upto</span></label>
            <input type="date" id="Førerkort_gyldig_til" name="Førerkort_gyldig_til" value={formData.Førerkort_gyldig_til} onChange={e => setFormData({ ...formData, Førerkort_gyldig_til: e.target.value })} />
            {formErrors.Førerkort_gyldig_til && <span className="error">{formErrors.Førerkort_gyldig_til}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="Kjøreseddel_gyldig_til">Kjøreseddel<span>gyldig til</span></label>
            <input type="text" id="Kjøreseddel_gyldig_til" name="Kjøreseddel_gyldig_til" value={formData.Kjøreseddel_gyldig_til} onChange={e => setFormData({ ...formData, Kjøreseddel_gyldig_til: e.target.value })} />
            {formErrors.Kjøreseddel_gyldig_til && <span className="error">{formErrors.Kjøreseddel_gyldig_til}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="Sjåfør_ID_nr">Sjåfør ID nr. / Driver's ID No.<span>(Taxisentral)</span></label>
            <input type="text" id="Sjåfør_ID_nr" name="Sjåfør_ID_nr" value={formData.Sjåfør_ID_nr} onChange={e => setFormData({ ...formData, Sjåfør_ID_nr: e.target.value })} />
            {formErrors.Sjåfør_ID_nr && <span className="error">{formErrors.Sjåfør_ID_nr}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="Sjåfør_ID_Validy">Sjåfør ID / Driver's ID Gyldig / Validy</label>
            <input type="date" id="Sjåfør_ID_Validy" name="Sjåfør_ID_Validy" value={formData.Sjåfør_ID_Validy} onChange={e => setFormData({ ...formData, Sjåfør_ID_Validy: e.target.value })} />
            {formErrors.Sjåfør_ID_Validy && <span className="error">{formErrors.Sjåfør_ID_Validy}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="Bankkonto">Bankkonto / Bank Account</label>
            <input type="text" id="Bankkonto" name="Bankkonto" value={formData.Bankkonto} onChange={e => setFormData({ ...formData, Bankkonto: e.target.value })} />
            {formErrors.Bankkonto && <span className="error">{formErrors.Bankkonto}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="Annen_jobb_nå">Annen jobb nå ? / Other job now ?</label>
            <input type="text" id="Annen_jobb_nå" name="Annen_jobb_nå" value={formData.Annen_jobb_nå} onChange={e => setFormData({ ...formData, Annen_jobb_nå: e.target.value })} />
            {formErrors.Annen_jobb_nå && <span className="error">{formErrors.Annen_jobb_nå}</span>}
          </div>
          <div className="form-group full-width">
            <label htmlFor="pårørendes_navn_og_telefonnummer">Nærmeste pårørendes ForNavn og telefonnummer <span>(i nødstilfelle)</span><br />Next of kin's name and telephone number<span> (in case of emergency)</span></label>
            <input type="text" id="pårørendes_navn_og_telefonnummer" name="pårørendes_navn_og_telefonnummer" value={formData.pårørendes_navn_og_telefonnummer} onChange={e => setFormData({ ...formData, pårørendes_navn_og_telefonnummer: e.target.value })} />
            {formErrors.pårørendes_navn_og_telefonnummer && <span className="error">{formErrors.pårørendes_navn_og_telefonnummer}</span>}
          </div>
          <button className='r-btn'>Sende inn &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
          </svg></button>
        </form>
      </div>
      <Footer></Footer>
    </>
  );
}

export default RegisterDriver;