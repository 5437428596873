import React, { useState } from 'react';
import DOMPurify from 'dompurify'; // Import DOMPurify
import Navbar from './navbar';
import Footer from './footer';
import '../Components/booknow.css';

function BookNow(props) {
  const [formData, setFormData] = useState({
    Navn: '',
    Telefon: '',
    Valg: '',
    Henteadresse: '',
    Leveringsadresse: '',
    Dato: '',
    Tid: ''
  });

  const [errors, setErrors] = useState({});

  function sanitizeInput(input) {
    return DOMPurify.sanitize(input);
  }

  function validate() {
    const newErrors = {};

    if (!formData.Navn) {
      newErrors.Navn = 'Navn er påkrævet';
    }
    if (!formData.Telefon) {
      newErrors.Telefon = 'Telefon er påkrævet';
    } else if (formData.Telefon.length < 10) {
      newErrors.Telefon = 'Telefon skal være større end 10 cifre';
    }
    
    if (!formData.Valg) {
      newErrors.Valg = 'Valg er påkrævet';
    }

    if (!formData.Henteadresse) {
      newErrors.Henteadresse = 'Henteadresse er påkrævet';
    }

    if (!formData.Leveringsadresse) {
      newErrors.Leveringsadresse = 'Leveringsadresse er påkrævet';
    }

    if (!formData.Dato) {
      newErrors.Dato = 'Dato 7y';
    }

    if (!formData.Tid) {
      newErrors.Tid = 'Tid er påkrævet';
    }

    return newErrors;
  }

  function handleSubmit(e) {
    e.preventDefault();

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const sanitizedData = {
      Navn: sanitizeInput(formData.Navn),
      Telefon: sanitizeInput(formData.Telefon),
      Valg: sanitizeInput(formData.Valg),
      Henteadresse: sanitizeInput(formData.Henteadresse),
      Leveringsadresse: sanitizeInput(formData.Leveringsadresse),
      Dato: sanitizeInput(formData.Dato),
      Tid: sanitizeInput(formData.Tid)
    };

    const formDatab = new FormData();
    formDatab.append('Navn', sanitizedData.Navn);
    formDatab.append('Telefon', sanitizedData.Telefon);
    formDatab.append('Valg', sanitizedData.Valg);
    formDatab.append('Henteadresse', sanitizedData.Henteadresse);
    formDatab.append('Leveringsadresse', sanitizedData.Leveringsadresse);
    formDatab.append('Dato', sanitizedData.Dato);
    formDatab.append('Tid', sanitizedData.Tid);
    formDatab.append('sheet', 'booknowformdata'); // Specify the sheet parameter here

    fetch("https://script.google.com/macros/s/AKfycbxHi7oWSPwSNHumHw0tevfhHPy3Ca2eU0zvVxSlu-2dq8ITHRhaFjuap8M_imqGq81rqw/exec", {
      method: "POST",
      body: formDatab
    }).then(response => {
      console.log('Success:', response);
      setFormData({
        Navn: '',
        Telefon: '',
        Valg: '',
        Henteadresse: '',
        Leveringsadresse: '',
        Dato: '',
        Tid: ''
      });
      setErrors({});
    }).catch(error => {
      console.error('Error:', error);
    });
  }

  return (
    <>
      <Navbar />
      <div>
      <div className="hero">
                <div className="text">
                    <h1><b>Bestill VIPCab nå</b></h1>
                    <p>Qorem ipsum dolor sit amet, consectetur adipiscing<br />elit. Etiam eu turpis molestie, dictum est a, mattis<br />tellus.Qorem ipsum dolor sit amet, consectetur<br />adipiscing elit. Etiam eu turpis molestie, dictum est<br />a, mattis tellus.</p>
                </div>
                <div className="image">
                    <img src="/assets/book hero.png" alt="" />
                </div>
            </div>
      </div>
      <div className="booking-form-container">
        <h1>Vennligst fyll ut dette skjemaet</h1>
        <form className="booking-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="Navn">Navn</label>
            <input type="text" id="Navn" name="Navn" value={formData.Navn} onChange={e => setFormData({ ...formData, Navn: e.target.value })} />
            {errors.Navn && <span className="error">{errors.Navn}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="Telefon">Telefon</label>
            <input type="tel" id="Telefon" name="Telefon" value={formData.Telefon} onChange={e => setFormData({ ...formData, Telefon: e.target.value })} />
            {errors.Telefon && <span className="error">{errors.Telefon}</span>}
          </div>
          <div className="form-group full-width">
            <label htmlFor="Valg">Valg</label>
            <input type="text" id="Valg" name="Valg" value={formData.Valg} onChange={e => setFormData({ ...formData, Valg: e.target.value })} />
            {errors.Valg && <span className="error">{errors.Valg}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="Henteadresse">HenteAdresse</label>
            <input type="text" id="Henteadresse" name="Henteadresse" value={formData.Henteadresse} onChange={e => setFormData({ ...formData, Henteadresse: e.target.value })} />
            {errors.Henteadresse && <span className="error">{errors.Henteadresse}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="Leveringsadresse">Leveringsadresse</label>
            <input type="text" id="Leveringsadresse" name="Leveringsadresse" value={formData.Leveringsadresse} onChange={e => setFormData({ ...formData, Leveringsadresse: e.target.value })} />
            {errors.Leveringsadresse && <span className="error">{errors.Leveringsadresse}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="Dato">Dato</label>
            <input type="date" id="Dato" name="Dato" value={formData.Dato} onChange={e => setFormData({ ...formData, Dato: e.target.value })} />
            {errors.Dato && <span className="error">{errors.Dato}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="Tid">Tid</label>
            <input type="time" id="Tid" name="Tid" value={formData.Tid} onChange={e => setFormData({ ...formData, Tid: e.target.value })} />
            {errors.Tid && <span className="error">{errors.Tid}</span>}
          </div>
          <button className='book-btn'>Bestill &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" /></svg></button>
        </form>
      </div>
      <Footer />
    </>
  );
}

export default BookNow;
